<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-12 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor SO <span class="text-danger">*</span></label>
                    <model-select :options="needData1" placeholder="Pilih Projek" v-model="currentData.id_project"
                      style="z-index: 101;">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Perusahaan </label>
                    <input type="text" v-model="currentData.nama_customer" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Rute </label>
                    <input type="text" v-model="currentData.route" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Muatan</label>
                    <input type="text" v-model="currentData.jenis_muatan" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal SO</label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.so_date"
                        disabled />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor PO </label>
                    <input type="text" v-model="currentData.po_no" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor DO </label>
                    <input type="text" v-model="currentData.do_no" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor STO </label>
                    <input type="text" v-model="currentData.sto_no" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor SPK </label>
                    <input type="text" v-model="currentData.spk_no" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Kuantitas SO </label>
                    <input type="text" v-model="currentData.so_qty" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12 ">
                  <div class="form-group">
                    <label>Toleransi Susut </label>
                    <div class="position-relative w-100">
                      <input type="number" v-model="currentData.toleransi_susut" placeholder="" disabled />
                      <div class="position-absolute top-0 end-0 me-4 pe-2 h-100 d-flex align-items-center">%</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Lokasi Muat </label>
                    <input type="text" v-model="currentData.lokasi_muat" placeholder="" disabled />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Lokasi Bongkar </label>
                    <input type="text" v-model="currentData.lokasi_bongkar" placeholder="" disabled />
                  </div>
                </div>

                <div class="col-lg-12 col-sm-6 col-12 mb-5 mt-5"></div>



                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jumlah Angkutan</label>
                    <div class="position-relative w-100">
                      <input type="number" v-model="currentData.qty" placeholder="" required />
                      <div class="position-absolute top-0 end-0 me-4 pe-2 h-100 d-flex align-items-center">kg</div>
                    </div>
                  </div>
                </div> -->
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No. Pol <span class="text-danger">*</span></label>
                    <model-select :options="needData3" placeholder="Pilih Aset (No polisi)"
                      v-model="currentData.id_asset" style="z-index: 501;" > <!-- isDisabled="true"-->
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Supir <span class="text-danger">*</span></label>
                    <model-select :options="needData2" placeholder="Pilih Supir" v-model="currentData.id_driver" style="z-index: 502;">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Kategori Kendaraan</label>
                    <input type="text" v-model="currentData.jenis_aset" placeholder="" disabled />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Kendaraan</label>
                    <input type="text" v-model="currentData.kendaraan" placeholder="" disabled />
                  </div>
                </div>
                
                
                
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Muat <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false"
                        v-model="currentData.tanggal_muat" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Bongkar <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 101;">
                      <datepicker class="picker" :editable="true" :clearable="false"
                        v-model="currentData.tanggal_bongkar" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>



                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Voucher <span class="text-danger">*</span></label>
                    <vue-select :options="VoucherList" v-model="currentData.jenis_voucher" placeholder="None"
                      required />
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Menggunakan Supervisor</label>
                    <input type="checkbox" name="checkbox" v-model="currentData.is_supervisor" />
                  </div>
                </div>

                <pageheader :title="currentData.jenis_voucher !== null ? currentData.jenis_voucher : 'NO-VOUCHER'"
                  title1="" v-if="currentData.jenis_voucher != null" />

                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher == 'VOUCHER'">
                  <div class="form-group">
                    <label>No Voucher</label>
                    <input type="text" v-model="currentData.no_voucher" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher == 'VOUCHER'">
                  <div class="form-group">
                    <label>Jenis BBM</label>
                    <vue-select :options="BbmList" v-model="currentData.jenis_bbm" placeholder="None" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher == 'VOUCHER'">
                  <div class="form-group">
                    <label>Permintaan dalam liter</label>
                    <input type="number" v-model="currentData.permintaan" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null">
                  <div class="form-group">
                    <label>Uang jalan <span class="text-danger">*</span></label>
                    <input type="text" class="mb-1" v-model="currentData.uang_jalan" placeholder="Rp 0,-"
                      @input="formatCurrency('uang_jalan', currentData.uang_jalan)" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null">
                  <div class="form-group">
                    <label>Uang Tinggal <span class="text-danger">*</span></label>
                    <input type="text" class="mb-1" v-model="currentData.uang_tinggal" placeholder="Rp 0,-"
                      @input="formatCurrency('uang_tinggal', currentData.uang_tinggal)" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null">
                  <div class="form-group">
                    <label>Langsir <span class="text-danger">*</span></label>
                    <!-- <input type="text" class="mb-1" v-model="currentData.uang_langsir" placeholder="Rp 0,-"
                      @input="formatCurrency('uang_langsir', currentData.uang_langsir)" required disabled /> -->
                    <input type="text" class="mb-1" v-model="currentData.langsir" placeholder="Rp 0,-"
                      @input="formatCurrency('langsir', currentData.langsir)" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null">
                  <div class="form-group">
                    <label>Tambahan<span class="text-danger">*</span></label>
                    <!-- <input type="text" class="mb-1" v-model="currentData.bonus" placeholder="Rp 0,-"
                      @input="formatCurrency('bonus', currentData.bonus)" required disabled /> -->
                    <input type="text" class="mb-1" v-model="currentData.tambahan" placeholder="Rp 0,-"
                      @input="formatCurrency('tambahan', currentData.tambahan)" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.jenis_voucher != null">
                  <div class="form-group">
                    <label>Keterangan</label>
                    <input type="text" v-model="currentData.keterangan" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-show="currentData.jenis_voucher == 'VOUCHER'">
                  <div class="form-group">
                    <label>File Voucher <span class="text-danger">*</span></label>
                    <input type="file" class="form-control" ref="file" />
                  </div>
                </div>



                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <button type="button" @click="newTrip" class="btn btn-submit me-2">Trip Baru</button>
                  <router-link to="tripreguler" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-group input[type="number"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="number"]:focus {
  border-color: #043249;
  background: #fff;
}

</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiahInput, rupiahoutput, moneyFormat} from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Tambah Trip Reguler",
      title1: "atur Trip Reguler",
      config: "",
      api: "",

      //data
      currentData: {
        permintaan: "",
        uang_jalan: "",
        uang_tinggal: "",
        // uang_langsir: "",
        langsir: "",
        // bonus: "",
        tambahan: "",
        id_asset: "",
        id_driver: "",
        jenis_aset: "",
        nama_customer: "",
      },
      VoucherList: ["VOUCHER", "NON - VOUCHER"],
      BbmList: ["Pertamax Racing", "Pertamax Turbo", "Pertamax", "Pertalite", "Premium", "Pertamina Dex", "Dexlite", "Solar"],
      needData1: [],
      needData2: [],
      needData3: [],


    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.prepareData();
    this.currentData.tanggal_muat = new Date();
    this.currentData.tanggal_bongkar = new Date();

  },
  watch: {
    "currentData.id_project": {
      handler(newValue) {
        const index = this.needData1.findIndex((record) => record.value === newValue);
        if (index > -1) {
          this.currentData.lokasi_muat = this.needData1[index].lokasi_muat;
          this.currentData.lokasi_bongkar = this.needData1[index].lokasi_bongkar;
          this.currentData.jenis_muatan = this.needData1[index].jenis_muatan;
          this.currentData.route = this.needData1[index].route;
          this.currentData.nama_customer = this.needData1[index].nama_customer;
          // this.currentData.uang_jalan = rupiahInput(this.needData1[index].uang_jalan);
          // this.currentData.uang_langsir = rupiahInput(this.needData1[index].uang_langsir);
          // this.currentData.langsir = rupiahInput(this.needData1[index].langsir);
          // this.currentData.uang_tinggal = rupiahInput(this.needData1[index].uang_tinggal);
          // this.currentData.bonus = rupiahInput(this.needData1[index].uang_tambahan);
          // this.currentData.tambahan = rupiahInput(this.needData1[index].tambahan);
          this.currentData.sto_no = this.needData1[index].sto_no;
          this.currentData.do_no = this.needData1[index].do_no;
          this.currentData.spk_no = this.needData1[index].spk_no;
          this.currentData.po_no = this.needData1[index].po_no;
          this.currentData.toleransi_susut = this.needData1[index].toleransi_susut;
          this.currentData.so_qty = moneyFormat(this.needData1[index].so_qty);
          this.currentData.so_date = new Date(this.needData1[index].so_date);

        }

      }
    },
    // "currentData.id_asset": {
    //   handler(newValue) {
    //     const index = this.needData3.findIndex((record) => record.value === newValue);
    //     if (index > -1) {
    //       this.currentData.jenis_aset = this.needData3[index].jenis;
          
    //     }

    //   }
    // },
    // "currentData.id_driver": {
    //   handler(newValue) {
    //     const index = this.needData2.findIndex((record) => record.value === newValue);
    //     if (index > -1) {
    //       this.currentData.id_asset = this.needData2[index].id_asset;
    //       const index2 = this.needData3.findIndex((record) => record.value === this.needData2[index].id_asset);
    //       if (index2 > -1) {
    //         this.currentData.kendaraan = this.needData3[index2].nama_asset;

    //       }

          

    //     }

    //   }
    // },

    "currentData.id_asset": {
  handler(newValue) {
    // Find the index of the asset in needData3
    const assetIndex = this.needData3.findIndex((record) => record.value === newValue);
    if (assetIndex > -1) {
      this.currentData.jenis_aset = this.needData3[assetIndex].jenis;
      this.currentData.kendaraan = this.needData3[assetIndex].nama_asset;

      // Find the driver that matches the selected asset
      const driverIndex = this.needData2.findIndex((record) => record.id_asset === newValue);
      if (driverIndex > -1) {
        this.currentData.id_driver = this.needData2[driverIndex].value;
      }
    }
  }
},
"currentData.id_driver": {
  handler(newValue) {
    // Find the index of the driver in needData2
    const driverIndex = this.needData2.findIndex((record) => record.value === newValue);
    if (driverIndex > -1) {
      // this.currentData.id_asset = this.needData2[driverIndex].id_asset;

      // // Find the asset that matches the selected driver
      // const assetIndex = this.needData3.findIndex((record) => record.value === this.needData2[driverIndex].id_asset);
      // if (assetIndex > -1) {
      //   this.currentData.kendaraan = this.needData3[assetIndex].nama_asset;
      //   this.currentData.jenis_aset = this.needData3[assetIndex].jenis;
      // }
    }
  }
}

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    formatCurrency(label, number) {
      this.currentData[label] = rupiahInput(number);
    },

    
    prepareData() {
      // needData1
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      // for filter
      const filterData1 = needData1.filter(item => item.jenis === 'REGULER');
      filterData1.forEach((item, index) => {
        const element = {
          value: item.id, text: item.nama,
          lokasi_muat: item.lokasi_muat,
          lokasi_bongkar: item.lokasi_bongkar,
          jenis_muatan: item.jenis_muatan,
          route: item.route,
          // uang_jalan: item.uang_jalan,
          // uang_langsir: item.uang_langsir,
          // langsir: item.langsir,
          // uang_tinggal: item.uang_tinggal,
          // uang_tambahan: item.uang_tambahan,
          nama_customer: item.nama_customer,
          sto_no: item.sto_no,
          do_no: item.do_no,
          spk_no: item.spk_no,
          po_no: item.po_no,
          toleransi_susut: item.toleransi_susut,
          so_qty: item.so_qty,
          so_date: item.so_date,
        }


        this.needData1.push(element);
      });

      // needData2
      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((item, index) => {
        const element = { value: item.id, text: item.nama, id_asset: item.id_asset }
        this.needData2.push(element);
      });

      // needData3
      const needData3 = JSON.parse(localStorage.getItem('needData3'));
      needData3.forEach((item, index) => {
        const element = { value: item.id, text: item.nama, jenis: item.jenis, nama_asset: item.nama_asset }
        this.needData3.push(element);
      });

      const approveData = JSON.parse(localStorage.getItem('approveData'));
      if (approveData != null) {

        this.currentData.id_asset = approveData.id_assets;
        this.currentData.id_driver = approveData.id_drivers;


        // const index = this.needData3.findIndex((record) => record.value === approveData.id_assets);
        const index = this.needData2.findIndex((record) => record.value === approveData.id_drivers);
        if (index > -1) {
          this.currentData.jenis_aset = this.needData3[index].jenis;
        }
      }

    },

    newTrip() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("jenis", 'REGULER');

      formData.delete("tanggal_muat");
      if (this.currentData.tanggal_muat != 'Invalid Date' && this.currentData.tanggal_muat != new Date()) {
        var formattedDate = this.currentData.tanggal_muat.toISOString().split('T')[0];
        formData.append("tanggal_muat", formattedDate);
      } else {
        formData.append("tanggal_muat", null);
      }

      //tanggal bongkar
      formData.delete("tanggal_bongkar");
      if (this.currentData.tanggal_bongkar != 'Invalid Date' && this.currentData.tanggal_bongkar != new Date()) {
        var formattedDate = this.currentData.tanggal_bongkar.toISOString().split('T')[0];
        formData.append("tanggal_bongkar", formattedDate);
      } else {
        formData.append("tanggal_bongkar", null);
      }

      formData.delete("is_supervisor");
      if (this.currentData.is_supervisor == true) {
        formData.append('is_supervisor', 'AKTIF');
      } else {
        formData.append('is_supervisor', 'TIDAK');
      }


      formData.delete("uang_jalan");
      formData.append('uang_jalan', rupiahoutput(this.currentData.uang_jalan));
      formData.delete("uang_tinggal");
      formData.append('uang_tinggal', rupiahoutput(this.currentData.uang_tinggal));
      formData.delete("langsir");
      formData.append('langsir', rupiahoutput(this.currentData.langsir));
      formData.delete("tambahan");
      formData.append('tambahan', rupiahoutput(this.currentData.tambahan));

      if (this.$refs.file.value != "") {
        formData.append("file", this.$refs.file.files[0]);
      }



      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'operationalinsert';
      } else {
        this.api = base_url + 'operationalinsert';
      }



      formData.append("jenis", "REGULER");


      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            localStorage.removeItem("approveData");

            const currentProject = this.currentData.id_project;

            // Clear currentData fields
            this.currentData = {
              // Reset the structure based on the initial format of currentData
              id_project: currentProject,
              lokasi_muat: this.currentData.lokasi_muat,
              lokasi_bongkar: this.currentData.lokasi_bongkar,
              jenis_muatan: this.currentData.jenis_muatan,
              route: this.currentData.route,
              nama_customer: this.currentData.nama_customer,
              sto_no: this.currentData.sto_no,
              do_no: this.currentData.do_no,
              spk_no: this.currentData.spk_no,
              po_no: this.currentData.po_no,
              toleransi_susut: this.currentData.toleransi_susut,
              so_qty: this.currentData.so_qty,
              so_date: this.currentData.so_date,
              tanggal_muat: this.currentData.tanggal_muat,
              tanggal_bongkar: this.currentData.tanggal_bongkar,
              is_supervisor: false,
              uang_jalan: '',
              uang_tinggal: '',
              langsir: '',
              tambahan: '',
              
            };

             

            // Clear file input
            this.$refs.file.value = "";

            // goPage("addtripreguler");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("jenis", 'REGULER');

      formData.delete("tanggal_muat");
      if (this.currentData.tanggal_muat != 'Invalid Date' && this.currentData.tanggal_muat != new Date()) {
        var formattedDate = this.currentData.tanggal_muat.toISOString().split('T')[0];
        formData.append("tanggal_muat", formattedDate);
      } else {
        formData.append("tanggal_muat", null);
      }

      //tanggal bongkar
      formData.delete("tanggal_bongkar");
      if (this.currentData.tanggal_bongkar != 'Invalid Date' && this.currentData.tanggal_bongkar != new Date()) {
        var formattedDate = this.currentData.tanggal_bongkar.toISOString().split('T')[0];
        formData.append("tanggal_bongkar", formattedDate);
      } else {
        formData.append("tanggal_bongkar", null);
      }

      formData.delete("is_supervisor");
      if (this.currentData.is_supervisor == true) {
        formData.append('is_supervisor', 'AKTIF');
      } else {
        formData.append('is_supervisor', 'TIDAK');
      }


      formData.delete("uang_jalan");
      formData.append('uang_jalan', rupiahoutput(this.currentData.uang_jalan));
      formData.delete("uang_tinggal");
      formData.append('uang_tinggal', rupiahoutput(this.currentData.uang_tinggal));
      formData.delete("langsir");
      formData.append('langsir', rupiahoutput(this.currentData.langsir));
      formData.delete("tambahan");
      formData.append('tambahan', rupiahoutput(this.currentData.tambahan));

      if (this.$refs.file.value != "") {
        formData.append("file", this.$refs.file.files[0]);
      }



      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'operationalinsert';
      } else {
        this.api = base_url + 'operationalinsert';
      }



      formData.append("jenis", "REGULER");


      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            localStorage.removeItem("approveData");

            goPage("tripreguler");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>