<template>
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar-menu></sidebar-menu>
      <div class="page-wrapper">
        <div class="content">
          <pageaddheader :title="title" :title1="title1" :items="items" />
          <div class="card">
            <div class="card-body">
                <div class="table-top">
                    <div class="search-set">
                        <!-- <div class="search-path">
                            <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                :class="{ setclose: filter }">
                                <img src="@/assets/img/icons/filter.svg" alt="img" />
                                <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                            </a>
                        </div> -->
                        <div class="search-input">
                            <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                    alt="img" /></a>
                            <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                <label>
                                    <input type="search" class="form-control form-control-sm"
                                        placeholder="Search..." aria-controls="DataTables_Table_0"
                                        v-model="query" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="wordset">
                        <ul>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                        src="@/assets/img/icons/pdf.svg" alt="img" /></a>
                            </li>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                        src="@/assets/img/icons/excel.svg" alt="img" /></a>
                            </li>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                        src="@/assets/img/icons/printer.svg" alt="img" /></a>
                            </li>
                        </ul>
                    </div> -->
                </div>
                <!-- /Filter -->
                <!-- <div class="card mb-0" id="filter_inputs" :style="{
                    display: filter ? 'block' : 'none',
                }">
                    <div class="card-body pb-0">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="row">
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Product" placeholder="Choose Product" />
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Choose" placeholder="Choose Category" />
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Category" placeholder="Choose Sub Category" />
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Brand" placeholder="Brand" />
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Price" placeholder="Price" />
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-6 col-12">
                                        <div class="form-group">
                                            <a class="btn btn-filters ms-auto"><img
                                                    src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- /Filter -->
                <div class="table-responsive">
                    <a-table :columns="columns" :data-source="filteredData" @change="onChange">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'no'">
                                <div class="productimgname">
                                    {{ record.no }}
                                </div>
                            </template>
                            <!-- <template v-if="column.key === 'nama'">
                                <div class="productimgname">
                                    <a href="javascript:void(0);" class="product-img">
                                        <img :src="require('@/assets/img/product/' + record.img)"
                                            alt="product" />
                                    </a>
                                    <a href="javascript:void(0);">{{ record.nama }}</a>
                                </div>
                            </template> -->
                            <!-- <template v-if="column.key === 'ongkos_angkut'">
                                <div class="productimgname">
  
                                    <a href="javascript:void(0);">{{ rupiah(record.ongkos_angkut) }}</a>
                                </div>
                            </template> -->
                            <template v-if="column.key === 'status_project'">
                                <div class="productimgname">
  
                                    <a href="javascript:void(0);">{{ record.status_project }}</a>
                                </div>
                            </template>
                            <template v-if="column.key === 'dokumen_url'">
                                <div class="productimgname">
                                    <a :href="record.dokumen_url" target="_blank" class="product-img me-2">
                                        <vue-feather type="file" data-bs-toggle="tooltip"
                                            title="fe fe-file"></vue-feather>
                                    </a>
                                </div>
                            </template>
  
  
                            <template v-else-if="column.key === 'action'" class="bg-danger">
                                <!-- <a class="me-3" @click="show_izitoast">
                                    <img src="@/assets/img/icons/eye.svg" alt="img" />
                                </a> -->
  
  
                                <router-link class="me-3" to="editproject" @click="edit(record);">
                                    <img src="@/assets/img/icons/edit.svg" alt="img" />
                                </router-link>
                                <a class="confirm-text" href="javascript:void(0);"
                                    @click="terminate(record.id); showModal('terminate');">
                                    <img src="@/assets/img/icons/delete.svg" alt="img" />
                                </a>
  
  
  
                            </template>
                        </template>
                    </a-table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- delete modal -->
    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">Hapus Data</h5>
                  <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <form @submit.prevent="delete2">
                  <div class="modal-body">
                      Apakah anda yakin ingin menghapus data ini?
                  </div>
                  <div class="modal-footer d-flex justify-content-end">
                      <button type="button" class="btn  btn-secondary" @click="hideModal2('terminate')">batal</button>
                      <button type="submit" class="btn  btn-danger">Hapus</button>
                  </div>
              </form>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import Cookies from "js-cookie";
  import axios from "axios";
  import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom, rupiah } from "@/assets/js/function";
  
  export default {
      data() {
          return {
              filter: false,
              title: "Daftar Pengeluaran",
              title1: "Atur Pengeluaran",
              items: [
                  {
                      text: "Tambah Pengeluaran",
                      addname: "/addpengeluaran",
                  },
              ],
  
              //data
              data: [],
              columns: [
                  {
                      title: "No",
                      dataIndex: "no",
                      key: "no",
                      sorter: {
                          compare: (a, b) => {
                              a = a.no.toLowerCase();
                              b = b.no.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "Tanggal",
                      dataIndex: "date",
                      key: "date",
                      sorter: {
                          compare: (a, b) => {
                              a = a.date.toLowerCase();
                              b = b.date.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "No.Sumber",
                      dataIndex: "no_sumber",
                      key: "no_sumber",
                      sorter: {
                          compare: (a, b) => {
                              a = a.no_sumber.toLowerCase();
                              b = b.no_sumber.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  
                  {
                      title: "Keterangan",
                      dataIndex: "keterangan",
                      key: "keterangan",
                      sorter: {
                          compare: (a, b) => {
                              a = a.keterangan.toLowerCase();
                              b = b.keterangan.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "Total",
                      dataIndex: "total",
                      key: "total",
                      sorter: {
                          compare: (a, b) => {
                              a = a.total.toLowerCase();
                              b = b.total.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  
  
                  // {
                  //     title: "Dokumen ",
                  //     dataIndex: "dokumen_url",
                  //     key: "dokumen_url",
                  //     sorter: {
                  //         compare: (a, b) => {
                  //             a = a.dokumen_url.toLowerCase();
                  //             b = b.dokumen_url.toLowerCase();
                  //             return a > b ? -1 : b > a ? 1 : 0;
                  //         },
                  //     },
                  // },
  
  
                  {
                      title: "Aksi",
                      key: "action",
                      sorter: false,
                  },
              ],
  
              tanggal_terminate: "",
              alasan_terminate: "",
              id_terminate: "",
              nama_terminate: "",
  
              //filter
              query: "",
  
  
          };
  
  
      },
      created() {
          const token = Cookies.get("token_refresh");
          this.config = {
              headers: { Authorization: `Bearer ` + token },
              timeout: 30000,
          }
          this.index();
      },
  
      computed: {
          filteredData() {
              // Filter data based on selected jenis_kehadiran
              if (this.query == '') {
                  return this.data; // If no filter selected, return all data
              }
              const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison
  
              return this.data.filter(item =>
                  item.date.toLowerCase().includes(lowerCaseQuery) ||
                  item.no_sumber.toLowerCase().includes(lowerCaseQuery) ||
                  item.keterangan.toLowerCase().includes(lowerCaseQuery) ||
                  item.total.toLowerCase().includes(lowerCaseQuery) 
                  
              );
          }
      },
      methods: {
          rupiah,
          showModal(modal_name) {
              this.$refs[modal_name].classList.add('show');
              this.$refs[modal_name].style.display = 'block';
          },
  
          hideModal2(modal_name) {
              this.$refs[modal_name].classList.remove('show');
              this.$refs[modal_name].style.display = 'none';
  
          },
  
  
          // showAlert() {
          //     // Use sweetalert2
          //     Swal.fire({
          //         title: "Are you sure?",
          //         text: "You won't be able to revert this!",
          //         showCancelButton: true,
          //         confirmButtonColor: "#043249",
          //         cancelButtonColor: "#d33",
          //         confirmButtonText: "Yes, delete it!",
          //     }).then((result) => {
          //         if (result.isConfirmed) {
          //             Swal.fire("Deleted!", "Your file has been deleted.");
          //         }
          //     });
          // },
  
          index() {
              ShowLoading();
  
              if (process.env.NODE_ENV === "development") {
                  this.api = base_url + 'projects';
              } else {
                  this.api = base_url + 'projects';
              }
  
              axios
                  .post(this.api, '', this.config)
                  .then((response) => {
                      console.log(response)
                      if (response.data.status == true) {
                          this.data = [];
  
                          response.data.data.forEach((item, index) => {
                              let item_prepare = { ...item, no: (index + 1), };
                              this.data.push(item_prepare);
                          });
  
                          localStorage.setItem('needData1', JSON.stringify(response.data.customer));
                          localStorage.setItem('needData2', JSON.stringify(response.data.route));
                          localStorage.setItem('needData3', JSON.stringify(response.data.jenis_muatan));
  
                          CloseLoading();
                      }
  
  
                      if (response.data.status == false) {
                          AlertPopup("error", "", response.data.message, 1500, false);
                      }
                  })
                  .catch((error) => {
                      ErrorConnectionTimeOut(error);
                  });
  
          },
          edit(record) {
              localStorage.setItem('currentData', JSON.stringify(record));
          },
          delete2() {
              ShowLoading();
              let formData = new FormData();
              formData.append("id", this.id_terminate);
  
  
  
              // for (let [key, value] of formData.entries()) {
              //     console.log(`${key}: ${value}`);
              // }
  
              if (process.env.NODE_ENV === "development") {
                  this.api = base_url + 'projectsdelete';
              } else {
                  this.api = base_url + 'projectsdelete';
              }
  
              axios
                  .post(this.api, formData, this.config)
                  .then((response) => {
                      if (response.data.status == true) {
                          const index = this.data.findIndex((record) => record.id === this.id_terminate);
                          this.data.splice(index, 1);
                          CloseLoading();
                          AlertBottom(response.data.message);
                          this.hideModal2('terminate');
                      }
                      if (response.data.status == false) {
                          AlertPopup("error", "", response.data.message, 1500, false);
                      }
                  })
                  .catch((error) => {
  
                      ErrorConnectionTimeOut(error);
                  });
  
  
          },
          terminate(id) {
              this.id_terminate = id;
              this.tanggal_terminate = new Date();
          }
      },
      name: "productlist",
  };
  </script>