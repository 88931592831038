<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1" :items="items" />
                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top">
                            <div class="search-set">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Search..." aria-controls="DataTables_Table_0"
                                                v-model="query" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="wordset">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="@/assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="@/assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="@/assets/img/icons/printer.svg" alt="img" /></a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <!-- /Filter -->
                        <!-- <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- /Filter -->
                        <div class="table-responsive">
                            <a-table :columns="columns" :data-source="filteredData" @change="onChange">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>
                                    <!-- <template v-if="column.key === 'nama'">
                                        <div class="productimgname">
                                            <a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>
                                            <a href="javascript:void(0);">{{ record.nama }}</a>
                                        </div>
                                    </template> -->
                                    <!-- <template v-if="column.key === 'ongkos_angkut'">
                                        <div class="productimgname">

                                            <a href="javascript:void(0);">{{ rupiah(record.ongkos_angkut) }}</a>
                                        </div>
                                    </template> -->
                                    <template v-if="column.key === 'so_qty'">
                                        <div class="productimgname">

                                            <a href="javascript:void(0);">{{ moneyFormat(record.so_qty) }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'status_project'">
                                        <div class="productimgname">

                                            <a href="javascript:void(0);">{{ record.status_project }}</a>
                                        </div>
                                    </template>
                                    
                                    <template v-if="column.key === 'dokumen_url'">
                                        <div class="productimgname">
                                            <a :href="record.dokumen_url" target="_blank" class="product-img me-2">
                                                <vue-feather type="file" data-bs-toggle="tooltip"
                                                    title="fe fe-file"></vue-feather>
                                            </a>
                                        </div>
                                    </template>


                                    <template v-else-if="column.key === 'action'" class="bg-danger">
                                        <!-- <a class="me-3" @click="show_izitoast">
                                            <img src="@/assets/img/icons/eye.svg" alt="img" />
                                        </a> -->


                                        <router-link class="me-3" to="editproject" @click="edit(record);">
                                            <img src="@/assets/img/icons/edit.svg" alt="img" />
                                        </router-link>
                                        <a class="confirm-text" href="javascript:void(0);"
                                            @click="terminate(record.id); showModal('terminate');">
                                            <img src="@/assets/img/icons/delete.svg" alt="img" />
                                        </a>



                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Hapus Data</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        Apakah anda yakin ingin menghapus data ini?
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary" @click="hideModal2('terminate')">batal</button>
                        <button type="submit" class="btn  btn-danger">Hapus</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom, rupiah, moneyFormat } from "@/assets/js/function";

export default {
    data() {
        return {
            filter: false,
            title: "Daftar Projek",
            title1: "Atur Projek",
            items: [
                {
                    text: "Tambah Projek baru",
                    addname: "/addproject",
                },
            ],

            //data
            data: [],
            columns: [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no.toLowerCase();
                            b = b.no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Status Projek",
                    dataIndex: "status_project",
                    key: "status_project",
                    sorter: {
                        compare: (a, b) => {
                            a = a.status_project.toLowerCase();
                            b = b.status_project.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "PO Number",
                    dataIndex: "po_no",
                    key: "po_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.po_no.toLowerCase();
                            b = b.po_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "STO Number",
                    dataIndex: "sto_no",
                    key: "sto_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.sto_no.toLowerCase();
                            b = b.sto_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "DO Number",
                    dataIndex: "do_no",
                    key: "do_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.do_no.toLowerCase();
                            b = b.do_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nama Pelanggan",
                    dataIndex: "nama_customer",
                    key: "nama_customer",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_customer.toLowerCase();
                            b = b.nama_customer.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nama Rute",
                    dataIndex: "route",
                    key: "route",
                    sorter: {
                        compare: (a, b) => {
                            a = a.route.toLowerCase();
                            b = b.route.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Jenis_muatan",
                    dataIndex: "nama_jenis_muatan",
                    key: "nama_jenis_muatan",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_jenis_muatan.toLowerCase();
                            b = b.nama_jenis_muatan.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nomor SPK",
                    dataIndex: "spk_no",
                    key: "spk_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.spk_no.toLowerCase();
                            b = b.spk_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nomor SO",
                    dataIndex: "so_no",
                    key: "so_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.so_no.toLowerCase();
                            b = b.so_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Tanggal SO",
                    dataIndex: "so_date",
                    key: "so_date",
                    sorter: {
                        compare: (a, b) => {
                            a = a.so_date.toLowerCase();
                            b = b.so_date.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Ketentuan SO",
                    dataIndex: "so_term",
                    key: "so_term",
                    sorter: {
                        compare: (a, b) => {
                            a = a.so_term.toLowerCase();
                            b = b.so_term.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Tanggal Penagihan SO",
                    dataIndex: "so_billing_date",
                    key: "so_billing_date",
                    sorter: {
                        compare: (a, b) => {
                            a = a.so_billing_date.toLowerCase();
                            b = b.so_billing_date.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Kuantitas SO",
                    dataIndex: "so_qty",
                    key: "so_qty",
                    sorter: {
                        compare: (a, b) => {
                            a = a.so_qty.toLowerCase();
                            b = b.so_qty.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },

                // {
                //     title: "Ongkos Angkut",
                //     dataIndex: "ongkos_angkut",
                //     key: "ongkos_angkut",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.ongkos_angkut.toLowerCase();
                //             b = b.ongkos_angkut.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                {
                    title: "Dokumen ",
                    dataIndex: "dokumen_url",
                    key: "dokumen_url",
                    sorter: {
                        compare: (a, b) => {
                            a = a.dokumen_url.toLowerCase();
                            b = b.dokumen_url.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },


                {
                    title: "Aksi",
                    key: "action",
                    sorter: false,
                },
            ],

            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            nama_terminate: "",

            //filter
            query: "",


        };


    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();
    },

    computed: {
        filteredData() {
            // Filter data based on selected jenis_kehadiran
            if (this.query == '') {
                return this.data; // If no filter selected, return all data
            }
            const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison

            return this.data.filter(item =>
                item.so_no.toLowerCase().includes(lowerCaseQuery) ||
                item.so_date.toLowerCase().includes(lowerCaseQuery) ||
                item.so_term.toLowerCase().includes(lowerCaseQuery) ||
                item.so_biling_date.toLowerCase().includes(lowerCaseQuery) ||
                item.so_qty.toLowerCase().includes(lowerCaseQuery) ||
                item.po_no.toLowerCase().includes(lowerCaseQuery) ||
                // item.ongkos_angkut.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_customer.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_route.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_jenis_muatan.toLowerCase().includes(lowerCaseQuery)
            );
        }
    },
    methods: {
        moneyFormat,
        rupiah,
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },


        // showAlert() {
        //     // Use sweetalert2
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         showCancelButton: true,
        //         confirmButtonColor: "#043249",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire("Deleted!", "Your file has been deleted.");
        //         }
        //     });
        // },

        index() {
            ShowLoading();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'projects';
            } else {
                this.api = base_url + 'projects';
            }

            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    console.log(response)
                    if (response.data.status == true) {
                        this.data = [];

                        response.data.data.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });

                        localStorage.setItem('needData1', JSON.stringify(response.data.customer));
                        localStorage.setItem('needData2', JSON.stringify(response.data.route));
                        localStorage.setItem('needData3', JSON.stringify(response.data.jenis_muatan));

                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        edit(record) {
            localStorage.setItem('currentData', JSON.stringify(record));
        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);



            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'projectsdelete';
            } else {
                this.api = base_url + 'projectsdelete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id) {
            this.id_terminate = id;
            this.tanggal_terminate = new Date();
        }
    },
    name: "productlist",
};
</script>
<style>
.ant-tooltip {
    display: none !important;
}
</style>